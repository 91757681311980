<template>
  <div>
    <div class="square-image"
         v-if="previewData"
         :style="{ 'background-image': 'url(' + previewData + ')' }"
         />
    <div v-else class="square-image lighter">
      <icon class="lighter-lighter" size="2" type="image" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "VideoPreview",
  components: {
    Icon,
  },
  props: {
    /*
    * @previewData String can be URL or base46-data
    * **/
    previewData: {type: String, default: ''},
  },
}
</script>

<style scoped>

</style>